import React from "react";

const Dropdown = ({onSelected, dog, ...props}) => {
  const { selectables } = props;

  function handleChange(event){
    onSelected(event.target.value)
  }


  return (
      <select
        value={dog.index}
        onChange={e => handleChange(e)}>
        {selectables.map(o => (
          <option key={o.index} value={o.index}>{o.name}</option>
        ))}
      </select>
  )
}


export default Dropdown;
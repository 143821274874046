import React from 'react'
import DC_closeup from '../images/GC_closeup_min@2x.png'
import { Link } from 'react-router-dom'
import Footer from './sections/Footer'

const Pitch = (props) => {
  return <main id="vote">
    <section>
      <div className='container container-sm'>
        <div className='dog-catcher'>
          <img src={DC_closeup} loading='lazy' width="300" alt=""/>
        </div>
        <div className='page-description'>
          <h1>Strategy</h1>
          <p>DogCatcher's target audience are users stuck in illiquid DogCoins. As users deposit DogCoins to DogCatcher, they receive dog.catcher minted according to the Ethereum value of the DogCoins they deposit.</p>
          <p>DC smart contracts dump the deposited DogCoins using our optimal dumping formula. The Ethereum received is allocated towards bolstering dog.catcher liquidity.</p>
          <p>All dog.catcher tokens are minted by a fair deposit. For presale funders, the rate is 1 million per Ethereum. After activation, dog.catcher will be minted by a deposit of the targeted DogCoins.</p>
          <p>This is a fair and transparent launch. Over 90% of funds raised become protocol-owned liquidity.</p>
          <Link to="/vote">
          <button className="btn btn-main">
              Fund DogCatcher
          </button></Link>
          <p><a href="/dc_whitepaper.pdf">Read the Whitepaper</a></p>
        </div>
      </div>
    </section>
    <Footer />
  </main>
}


export default Pitch;
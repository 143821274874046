import React from "react";
import { Routes, Route } from "react-router-dom"
import Navbar from "./sections/Navbar";
import Home from "./Home";
import Vote from "./Vote";
import Pitch from "./Pitch";
import Mint from "./Mint";
import DCClub from "./DCClub";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import DCArtifact from "../contracts/DogCatcher.json";
import DCAddress from "../contracts/DogCatcher-address.json";
import OvenAddress from "../contracts/Oven-address.json";
import OvenArtifact from "../contracts/Oven.json"
import SSArtifact from "../contracts/ShibSkull.json";
import menu from './menu'
import erc20ABI from '../contracts/erc20'

// const HARDHAT_NETWORK_ID = '31337';

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
const BNZero = ethers.BigNumber.from("0")

export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      tokenData: undefined,
      selectedAddress: undefined,
      dcBalance: BNZero,
      ethBalance: BNZero,
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      dogContracts: {},
      selectedDog: {name: "Select Dog", index: -1, decimals: 18},
      dogDepositAmount: "0",
      dogBalances: {},
      dogApproved: false,
      offerData: { token: "", amount: 0, nowOffer: 0, vestOffer: 0, vestDate: 0}, //TODO: BigNumber format for default
      userVest: 0,
      userVestDate: undefined,
      showVest: false,
      userSelectedDog: false
    };

    this.state = this.initialState;
  }

  _ethDisplay (amount) {
    if (amount.isZero()) return "0";
    return ethers.utils.formatEther(amount).substring(0,8);
  }

_tokenDisplay = (amount, decimals) => {
  if (amount.isZero()) return "0";
  let tokenBalance = ethers.utils.formatUnits(amount, decimals)
  let realDecimals = tokenBalance.split(".")[1].length;
  tokenBalance = tokenBalance.substring(0, tokenBalance.length - realDecimals + 4);
  return ethers.utils.commify(tokenBalance);
}

signMessage = async(message) => {
  window.ethers = ethers
  const signature = await ethers.signMessage(message);
  console.log(signature)
  window.sig = signature
}

  render() {
    const balance = this._tokenDisplay(this.state.dcBalance, 9);
    const ethDisplay = this._ethDisplay(this.state.ethBalance);
    const {ethBalance, dcBalance, dogBalances, selectedDog, dogApproved, dogDepositAmount, offerData, userVest, userVestDate, presaleVotePercentages, selectedAddress, showVest} = this.state; //dogBalances
    let dogBalance = BNZero

    if (selectedDog.index >= 0) {
      dogBalance = dogBalances[selectedDog.address]
      dogBalance = this._tokenDisplay(dogBalance, selectedDog.decimals)
    }
 
     return <div>
      <Navbar connectWallet={() => this._connectWallet()}
              selectedAddress={this.state.selectedAddress}
              balance={balance}
              ethBalance={ethDisplay} />
      <Routes>
        {window.ethereum &&
        <Route path="/pitch" element={<Pitch ethBalance={ethDisplay}
        mint={(amt,vote) => this._presaleMint(amt,vote)}/>} /> }
        <Route path="/vote" element={<Vote txHash={this.state.txBeingSent} ethDisplay={ethDisplay} ethBalance={ethBalance}
          mint={(amt,vote) => this._presaleMint(amt,vote)} presalePercentages={presaleVotePercentages} />} />

        <Route path="/mint" element={<Mint txHash={this.state.txBeingSent} showVest={showVest} maxOut={this._maxOut} selectedAddress={selectedAddress} depositAmount={dogDepositAmount} setDepositAmount={this._setDepositAmount} onDogSelected={this._onDogSelected} dog={selectedDog} dogBalance={dogBalance}

        depositToOven={this._depositToOven} approveDC={this._approveDC} approved={dogApproved}
        instamint={this._instaMint} vestmint={this._vestMint} offerData={offerData}
        userVest={userVest} userVestDate={userVestDate} redeemVest={this._redeemVest}
        />} />
        <Route path="/dcclub" element={<DCClub signHoldings={this.signMessage} selectedAddress={selectedAddress} dcBalance={dcBalance} />}/>
        <Route path="/" element={<Home />} />
      </Routes>
      {/* {!window.ethereum &&
      <NoWalletDetected /> }

      <Hero selectedAddress={this.state.selectedAddress}
            connectWallet={this._connectWallet} />
      <Mint /> */}
    </div>
  }

  componentWillUnmount() {
    this._stopPollingData();
  }

  _connectWallet = async () => {
    this._initializeEthers();
  }

  _initializeEthers = async () => { 
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {  
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "2582c3515a11421da3330b72e65f7bf4" // required
          }
      }} // required
    });
    const instance = await web3Modal.connect();
    this._provider = new ethers.providers.Web3Provider(instance);

    const signer = this._provider.getSigner();

    this._dc = new ethers.Contract(
      DCAddress.address,
      DCArtifact.abi,
      signer
    );

    this._ss = new ethers.Contract(
      "0xDb3408f392d91401FD07De66DA5c33Ded0A44341",
      SSArtifact.abi,
      signer
    );


    let eventFilter = this._dc.filters.PresaleVote()
    let events = await this._dc.queryFilter(eventFilter)
    window.events = events
    this._oven = new ethers.Contract(
      OvenAddress.address,
      OvenArtifact.abi,
      this._provider.getSigner(0)
    );
    
    let presaleVotes = {1: BNZero, 2: BNZero, 3: BNZero, 4: BNZero, 5: BNZero, 6: BNZero}
    let presaleSum = BNZero
    for (let e of events) {
      let index = Number(e.args[1])
      let tally = presaleVotes[index]
      const amount = e.args[0]
      presaleVotes[index] = tally.add(amount)
      presaleSum = presaleSum.add(amount)
    }
    let presaleVoteKeys = [1,2,3,4,5,6]
    let presaleVotePercentages = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}
    for (let k of presaleVoteKeys) {
      let weight = presaleVotes[k]
      let percentage = weight.mul(1000).div(presaleSum)
      presaleVotePercentages[k] = Number(percentage.toString()) / 10
    }


    this.setState({
      selectedAddress: await signer.getAddress(),
      presaleVotes: presaleVotes,
      presaleVotePercentages: presaleVotePercentages
    });

    this._getDCData();
    this._startPollingData();

    // We reinitialize it whenever the user changes their account.
    instance.on("accountsChanged", ([newAddress]) => {
      console.log(newAddress);
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this.setState({
        selectedAddress: newAddress,
      });
      this._dc = new ethers.Contract(
        DCAddress.address,
        DCArtifact.abi,
        this._provider.getSigner(0)
      );
      this._startPollingData();
    });
    // We reset the dapp state if the network is changed
    instance.on("chainChanged", ([networkId]) => {
      this._stopPollingData();
      this._resetState();
    });

  }

  _tokenDisplay (amount) {
     if (amount.isZero()) return "0";
     let tokenBalance = ethers.utils.formatUnits(amount, 9)
     let realDecimals = tokenBalance.split(".")[1].length;
     tokenBalance = tokenBalance.substring(0, tokenBalance.length - realDecimals + 4);
     return ethers.utils.commify(tokenBalance);
   }

  _startPollingData() {
    this._pollDataInterval = setInterval(() => this._updateBalance(), 1000); 
    this._updateBalance();
  }

  _stopPollingData() {
    console.log("Stop polling data")
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }

  async _getDCData() {
    const name = await this._dc.name();
    const symbol = await this._dc.symbol();
    const active = await this._dc.isActive();
    this.setState({
     dcData: { name, symbol, active },
    })
  }

  async _updateBalance() {
    const ethBalance = await this._provider.getBalance(this.state.selectedAddress);
    const dcBalance = await this._dc.balanceOf(this.state.selectedAddress);
    const userVest = await this._dc.vestOf(this.state.selectedAddress);
    const userVestDate = new Date(1000 * userVest.vestedTime);
    let showVest = false

    let dogBalance = 0
    let {dogBalances, dogContracts, selectedDog, dogApproved, dogDepositAmount, offerData, userSelectedDog} = this.state;

    const originalMenuIndex = selectedDog.index

    for (let menuDog of menu) {
      if (!dogContracts[menuDog.address]) {
        let dogContract = new ethers.Contract(
          menuDog.address,
          erc20ABI,
          this._provider.getSigner(0)
        );
        dogContracts[menuDog.address] = dogContract;
        dogBalance = await dogContracts[menuDog.address].balanceOf(this.state.selectedAddress)
        dogBalances[menuDog.address] = dogBalance;
        if (selectedDog.index < 0 || (dogBalance.gt(BNZero) && !userSelectedDog)) {
          selectedDog = menuDog
        }
      }
    }

    if (selectedDog.index >= 0) {
      this.dogContract = dogContracts[selectedDog.address]
      dogBalance = await dogContracts[selectedDog.address].balanceOf(this.state.selectedAddress)
      dogBalances[selectedDog.address] = dogBalance;
      let approval =  await dogContracts[selectedDog.address].allowance(this.state.selectedAddress, this._ss.address)

      if (approval.gt(0)) {
        dogApproved = true
      }
      if (approval.gte(dogBalance) && dogDepositAmount && dogDepositAmount.length > 0 && dogDepositAmount !== "0") {

        // console.log(dogDepositAmount.toString())
        let dogAmountWithDecimals = ethers.utils.parseUnits(dogDepositAmount, selectedDog.decimals)
        const [nowOffer, vestOffer, vestTime] = await this._dc.otcOffer(selectedDog.address, dogAmountWithDecimals);
        var vestDate = new Date(1000 * vestTime)

        offerData =  { dogAmountWithDecimals, token: selectedDog.address, amount: dogDepositAmount, nowOffer: this._ethDisplay(nowOffer), vestOffer: this._ethDisplay(vestOffer), vestDate: vestDate.toString() };
      } else {
      }
    }

    if (selectedDog.index !== originalMenuIndex) {
      this.setState({
        dcBalance,
        ethBalance,
        dogBalance,
        dogBalances,
        dogContracts,
        selectedDog,
        dogApproved,
        offerData,
        userVest,
        userVestDate,
        showVest
      });
    } else {
      this.setState({
        dcBalance,
        ethBalance,
        dogBalance,
        dogBalances,
        dogContracts,
        dogApproved,
        offerData,
        userVest,
        userVestDate,
        showVest
      });
    }

 
  }

  async _transferTokens(to, amount) {
    try {
      this._dismissTransactionError();

      const tx = await this._dc.transfer(to, amount);
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  async _presaleMint(amount, vote) {
    try {
      this._dismissTransactionError();
      const tx = await this._dc.presaleMint(vote, { value: ethers.utils.parseEther(amount) });
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }

      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }  

  async _otcOffer(token, amount) {
    const [nowOffer, vestOffer, vestTime] = await this._dc.otcOffer(token, amount);
    var vestDate = new Date(1000 * vestTime)
    this.setState({
     offerData: { token, amount, nowOffer, vestOffer, vestDate },
    });
  }

  _redeemVest = async () => {
    try {
      this._dismissTransactionError();

      const tx = await this._dc.completeVest();
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  _maxOut = () => {
    if (this.state.dogBalance) {
      const {selectedDog} = this.state
      const {decimals} = selectedDog
      const balance = this._tokenDisplay(this.state.dogBalance, decimals).split(",").join("")
      this.setState({dogDepositAmount: balance})
    }
  }

  _setDepositAmount = (amount) => {
    if (amount && amount !== 0) {
      this.setState({dogDepositAmount: amount.toString()})
    } else {
      this.setState({dogDepositAmount: ""})
    }
  }

  _depositToOven = (dog, amount) => {

  }

  _approveDC = async (dog) => {
    try {
      this._dismissTransactionError();

      const tx = await this.dogContract.approve(this._ss.address, ethers.constants.MaxUint256);
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }


  _instaMint = async(token, amount) => {
    try {
      this._dismissTransactionError();

      const tx = await this._ss.instaMint(token, amount);
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }

      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  _onDogSelected = (index) => {
    this.setState({selectedDog: menu[index], userSelectedDog: true})
  }

  _vestMint = async(token, amount) => {
    try {
      this._dismissTransactionError();

      const tx = await this._dc.vestMint(token, amount);
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }

      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }  

  async _approveToken() {
    //Approves DC to use the selected token.
    try {
      this._dismissTransactionError();

      const amount = this.state.tokenBalance; //should use selected balance
      const tx = await this._selected.approve(DCAddress.address, amount);
      this.setState({ txBeingSent: tx.hash });

      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      } 
      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  _dismissTransactionError = () => {
    this.setState({ transactionError: undefined });
  }

  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }

  _resetState() {
    this.setState(this.initialState);
  }


  _checkNetwork() {
    return true;
    // const netv = window.ethereum.networkVersion;
    // if (netv === "1" || netv === 1) {
    //   return true;
    // }

    // this.setState({ 
    //   networkError: 'Please connect Metamask to Localhost:8545'
    // });

    // return false;
  }
}

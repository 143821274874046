import React from 'react'
import Logo from '../../images/logo_min@4x.png'


const Hero = (props) => {
  return (
    <section id="hero">
      <div className="container-md">
        <div className='hero-area'>
          <img src={Logo} loading='lazy' alt=""/>
        </div>
        <div className='hero-description'>
          <h1>{props.text1}</h1>
          <p>{props.text2}</p>
        </div>
      </div>
    </section>
  )
}


export default Hero;
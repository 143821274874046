import React from 'react'

const Hamburger = ({ visible, ...restProps }) => {
  let classes = "hamburger";
  if (visible) classes += " active";

  return <div className={classes} {...restProps}>
          <span className='hamburger-inner' />
          <span className='hamburger-inner' />
          <span className='hamburger-inner' />
        </div>
}

export default Hamburger
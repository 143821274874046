export default [
  // {
  //   name: "Saitama Inu",
  //   symbol: "SAITAMA",
  //   img: require('../images/icons/saitama-min.png'),
  //   index: 0,
  //   address: "0x8b3192f5eebd8579568a2ed41e6feb402f93f73f",
  // },
  // {
  //   name: "Floki Inu",
  //   symbol: "FLOKI",
  //   img: require('../images/icons/floki-inu-min.png'),
  //   index: 1,
  //   address: "0xcf0c122c6b73ff809c693db761e7baebe62b6a2e",
  // },
  {
    name: "Shiba Inu",
    symbol: "SHIB",
    img: require('../images/icons/sushi-min.png'),
    index: 0,
    address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
    decimals: 18
  },
  {
    name: "SUSHI",
    symbol: "🍣",
    img: require('../images/icons/shiba-inu-min.png'),
    index: 1,
    address: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
    decimals: 18
  }
/*  {
    name: "Saitama Inu",
    symbol: "SAITAMA",
    img: require('../images/icons/saitama-min.png'),
    index: 0,
    decimals: 9,
    address: "0xce3f08e664693ca792cace4af1364d5e220827b2",
  },
  {
    name: "Hoge",
    symbol: "HOGE",
    img: require('../images/icons/hoge-min.png'),
    index: 1,
    address: "0xfad45e47083e4607302aa43c65fb3106f1cd7607",
    decimals: 9
  },*/

  // {
  //   name: "Dogelon Mars",
  //   symbol: "ELON",
  //   img: require('../images/icons/dogelon-mars-min.png'),
  //   index: 4,
  //   address: "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3"
  // },
  // {
  //   name: "Kishu Inu",
  //   symbol: "KISHU",
  //   img: require('../images/icons/kishu-min.png'),
  //   index: 5,
  //   address: "0xa2b4c0af19cc16a6cfacce81f192b024d625817d"
  // }
]

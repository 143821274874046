import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines,  } from '@fortawesome/free-regular-svg-icons'
import { faDiscord, faTwitter, faYoutube  } from '@fortawesome/free-brands-svg-icons'

const Footer = (props) => {
  return <footer>
    <div className='links'>
      <a href="https://discord.gg/YBChwePxkC" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faDiscord} />
      </a>
      <a href="https://twitter.com/Dog__Catcher" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a href="https://www.youtube.com/channel/UChEeud5SWPxQ_OwP-ufk6XQ" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a href="dc_whitepaper.pdf" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFileLines} />
      </a>
    </div>
    <a href="https://etherscan.io/address/0x679A0B65a14b06B44A0cC879d92B8bB46a818633" target="_blank" rel="noopener noreferrer">
        Token Contract
      </a>
      <br/>
    <span>Dog Catcher © 2022.</span>
  </footer>
}


export default Footer
import React, { useState, useEffect } from 'react'
import DC_closeup from '../images/GC_closeup_min@2x.png'
import Footer from './sections/Footer'
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import { ethers } from "ethers";
import contracts from './dogs'
import { Link } from 'react-router-dom'

const Vote = ({txHash, presalePercentages, ...props}) => {
  const [selected, setSelected] = useState(null);
  const [mintAmount, setMintAmount] = useState("0");
  useEffect (() => { 
    window.scrollTo(0, 0) 
  }, []);
  const toggleSelection = (contract) => {
    if (selected) {
      if (selected.symbol === contract.symbol) {
        setSelected(null)
      } else {
        setSelected(contract)
      }
    } else {
      setSelected(contract)
    }
  }

  const dcAmount = mintAmount === "" ? "0" :ethers.utils.commify(ethers.utils.parseUnits(mintAmount, 6));

  return <main id="vote">
    <section>
      <div className='container container-sm'>
        <div className='dog-catcher'>
          <img src={DC_closeup} loading='lazy' width="300" alt=""/>
        </div>
        <div className='info'>
          <h1>Voting Info</h1>
          <p>DogCatcher targets a new dog based on DC holder votes.</p>
          <p>HOGE and Saitama are currently on the menu.</p>
          <Link to="/mint">
          <button className="btn btn-main">
              Deposit dogs on the menu to mint DC
          </button></Link>
          <p>More dogcoins will be added to the menu in upcoming votes.</p>
          <p>After that, the only way to mint is with targeted DogCoins. See the <a href="dc_whitepaper.pdf">whitepaper</a> for more information. </p>
        </div>
  </div>
    </section>

    <Footer />
  </main>
}


export default Vote;
import React from 'react'

import Van from '../../images/van_min@4x.png'
import Pie from '../../images/pie_min@4x.png'
import Wanted from '../../images/wanted_min@4x.png'



const Phases = (props) => {
  return <section id='phases'>
    <h1 className='page-title'>How it works</h1>
    <div className='container container-xl'>
      <div className='info'>
        <img src={Van} loading='lazy' width="140" alt=""/>
        <div>
          <h2>01. Catch em</h2>
          <p>Capture DogCoins and mint dog.catcher in exchange.</p>
        </div>
      </div>
      <div className='info'>
        <img src={Pie} loading='lazy' width="140" alt=""/>
        <div>
          <h2>02. Snack</h2>
          <p>The DogCatcher munches on tasty pie to keep his energy levels up.</p>
        </div>
      </div>
      <div className='info'>
        <img src={Wanted} loading='lazy' width="140" alt=""/>
        <div>
          <h2>03. Vote</h2>
          <p>Participate in the event by choosing the next dog to go catchin'.</p>
        </div>
      </div>
    </div>
  </section>
}


export default Phases
import React from 'react'
import menu from './menu'
import Dropdown from './Dropdown'
import Hero from './sections/Hero'
import { WaitingForTransactionMessage } from './WaitingForTransactionMessage'

const Mint = ({txHash, depositAmount, setDepositAmount, dogBalance, dog, onDogSelected, approved, depositToOven, approveDC,
  instamint, vestmint,
  offerData,
  userVest, userVestDate, redeemVest,
  selectedAddress,
  maxOut,
  ...props}) => {
  // const dcAmount = mintAmount === "" ? "0" :ethers.utils.commify(ethers.utils.parseUnits(mintAmount, 6));

  let dogBalanceDisplay = 0

  if (dog.index >= 0) {
    dogBalanceDisplay = `${dogBalance} ${dog.name}`
  }

  const selectables = menu;

  const { nowOffer, vestOffer, vestDate } = offerData;

  const approveText = `Approve ${dog.name}`
  const instamintText = `Instamint ${nowOffer} ETH worth of DC`
  const vestmintText = `Vestmint ${vestOffer} ETH worth of DC`

  let vestText
  let vestAmount = userVest.vestAmount;
  let showVest = false; //(userVest !== 0 && vestAmount && userVestDate)
  let canRedeem = (showVest && userVestDate && (new Date() > userVestDate))

  if (showVest) {
    vestText = canRedeem ? `Redeem ${vestAmount} of ETH for DC ` : `${vestAmount} of ETH redeemable for DC after ${userVestDate}`
  }

  return <main id="vote">

  <Hero text1="MINT"
        text2="Use SHIB and SUSHI to mint new DC!"/>
   {selectedAddress && <section>
   <div className='container container-sm moveUp'>
     <div>Select a targeted DogCoin</div>
     <Dropdown dog={dog} selectables={selectables} onSelected={(e) => {
       onDogSelected(e)
     }} />
     <br/>
     <div>
       You have {dogBalanceDisplay}.
     </div>
     <div className="info">
      <div>
        <label>{dog.name} amount to deposit for DogCatcher:</label>
      </div>
      <input step="1" className="form-control" type="number" name="amount" required value={depositAmount}
      onChange={(evt) => {setDepositAmount(evt.target.value)}}/>
            <button className="btn btn-max"
                    onClick={() => {
                        maxOut()
                    }}>MAX</button>
      <br />
      {txHash ?  <div></div>
        :
      <div className="mintField">
      {!approved && <button className="btn btn-mint"
                    onClick={() => {
                        approveDC(dog)
                    }}>{approveText}</button>}
      <br />
      {approved && <button className="btn btn-mint"
                    onClick={() => {
                        instamint(dog.address, offerData.dogAmountWithDecimals)
                    }}>{instamintText}</button>}
      <br />
     </div>}
    {txHash && (<WaitingForTransactionMessage txHash={txHash} />)}
    </div>
    </div>
   </section>}
  </main>
}


export default Mint;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Hamburger from '../util/Hamburger';

// function getRpcErrorMessage(error) {
//   return "Error"
//   if (error.data) {
//     return error.data.message;
//   }
//   return error.message;
// }

const Navbar = ({ connectWallet, selectedAddress, balance, ethBalance, ...restProps }) => {
  const [showMenu, setShowMenu] = useState(false);

  const addressText = selectedAddress ? `${selectedAddress.substring(0,5)}...${selectedAddress.substring(selectedAddress.length - 5, selectedAddress.length)}` : "Connect";

  
  return <nav id='navbar' {...restProps}>
    <div className='nav-container'>
      <div className='logo'>
        <Link to='/'><h1>Dog Catcher</h1></Link>
      </div>
      {/* {!restProps.transactionError && (
                <TransactionErrorMessage
                  message={getRpcErrorMessage(restProps.transactionError)}
                  dismiss={() => restProps.dismissTransactionError()}
                />
              )} */}
      <div>
        <>
          <Hamburger visible={showMenu}
                    onClick={() => setShowMenu(!showMenu)} />
          <ul id="primary-nav" className={showMenu ? 'active' : null}>
            {window.ethereum &&
            <li>
              <Link to="/mint">
              <button className="btn btn-main">
                Mint
              </button></Link>
            </li>}

            {balance &&
            <li>
              <span className='balance'>DC: {balance}</span>
            </li>}

            {ethBalance &&
            <li>
              <span className='balance'>ETH: {ethBalance}</span>
            </li>}

            <li>
              <button className='btn btn-connect'
                      onClick={connectWallet}>{addressText}</button>
            </li>
          </ul>
        </>
      </div>
    </div>
  </nav>
}


export default Navbar
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePollVertical, faMoneyCheckDollar, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const HowItWorks = (props) => {
  return <section className="home-section" id='how-it-works'>
    <div className='container container-lg'>
      <div className='details'>
        <div className='detail-items'>
          <span>
            <FontAwesomeIcon icon={faMoneyCheckDollar} />
            InstaMint
          </span>
          <span>
            <FontAwesomeIcon icon={faCircleDollarToSlot} />
            VestMint
          </span>
          <span>
            <FontAwesomeIcon icon={faSquarePollVertical} />
            Voting
          </span>
        </div>
        <p>DogCatcher lets you trade in your Dog tokens at an attractive rate, and finally pick a winner. Please see our <a href="dc_whitepaper.pdf" target="_blank" rel="noopener noreferrer">whitepaper</a> for more information.</p>
        <p>Deposit Dogcoins for DC</p>
        <Link to="/mint">
          <button className="btn btn-main">
            Mint
          </button></Link>
      </div>
      <div className='video'>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/_IYtUmWphig" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  </section>
}


export default HowItWorks
import React from "react";

export function WaitingForTransactionMessage({ txHash }) {
  const text = `https://etherscan.io/tx/${txHash}`;
  return (
    <div className="alert alert-info" role="alert">
      Waiting for <strong><a target="_blank" rel="noopener noreferrer" href={text}>{txHash}</a></strong> to be mined
    </div>
  );
}

import React from 'react'
import { BigNumber } from 'ethers'

const message = (address) => {
  return `My address ${address} holds at least 100k DC`;
}

const DCClub = ({selectedAddress,
  dcBalance,
  signHoldings,
  ...props}) => {
    if (!selectedAddress) {
      return <div>
        <div>Connect your Wallet to join the DC Holder Chat</div>
        <div>Private discord chat only for 100k+ holders.</div>
        </div>
    }

    const available = dcBalance.gte(BigNumber.from("100000"));
    if (!available) {
      return <div>
        <div>You need 100k+ DC to join the DC Holder Chat. </div>
      </div>
    }

  return <main id="vote">
   <section>
   <div className='container container-sm'>
     <div>Sign a message showing you hold 100k+ DC with your wallet.</div>
     <div>Post the signature in #holder-chat-entry on the <a href="https://discord.gg/YBChwePxkC" target="_blank" rel="noopener noreferrer">DC Discord</a></div>
     <button className="btn btn-mint"
                    onClick={() => {
                        signHoldings(message(selectedAddress))
                    }}>Sign</button>
    </div>
   </section>
  </main>
}


export default DCClub;
export default [
  {
    name: "Saitama Inu",
    symbol: "SAITAMA",
    img: require('../images/icons/saitama-min.png'),
    voteIndex: 1
  },
  {
    name: "Floki Inu",
    symbol: "FLOKI",
    img: require('../images/icons/floki-inu-min.png'),
    voteIndex: 2
  },
  {
    name: "Shiba Inu",
    symbol: "SHIB",
    img: require('../images/icons/shiba-inu-min.png'),
    voteIndex: 3
  },
  {
    name: "Hoge",
    symbol: "HOGE",
    img: require('../images/icons/hoge-min.png'),
    voteIndex: 4
  },
  {
    name: "Dogelon Mars",
    symbol: "ELON",
    img: require('../images/icons/dogelon-mars-min.png'),
    voteIndex: 5
  },
  {
    name: "Kishu Inu",
    symbol: "KISHU",
    img: require('../images/icons/kishu-min.png'),
    voteIndex: 6
  }
]

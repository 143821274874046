import React, { useEffect } from 'react'
import Hero from './sections/Hero'
import Phases from './sections/Phases'
import HowItWorks from './sections/HowItWorks'
import Footer from './sections/Footer'

const Home = (props) => {
  useEffect (() => { 
    window.scrollTo(0, 0) 
  }, []);
  return <main id="home">
            <Hero text1="Set your dogs free."
                  text2="They promised you the moon. DogCatcher offers you revenge. Get the revolutionary new Catcher token."/>
            <Phases />

            <HowItWorks />
            <Footer />
          </main>
}


export default Home